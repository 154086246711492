import React, { useState } from "react";
import StepsTemplate from "../StepsTemplate";
import { useAppDispatch } from "store";
import { setBirthtime } from "store/slices/registration";
import DefaultButton from "components/buttons/DefaultButton";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { dates } from "utils/defaults";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import "./styles.scss";

const BirthTimeSelect = () => {
  const [hour, setHour] = useState("");
  const [minutes, setMinutes] = useState("");
  const [ampm, setAmpm] = useState("");
  const dispatch = useAppDispatch();

  const onSelectBirthday = () => {
    ReactGA.event("birthtimeSelected");
    ReactPixel.trackCustom("birthtimeSelected");
    dispatch(setBirthtime(`${hour}:${minutes} ${ampm}`));
  };

  const updateTransformDay = (e: any) => {
    e.currentTarget.panels.forEach((panel: any) => {
      const rotateVal = -panel.progress * 20;
      const sinRot = Math.sin(Math.abs((rotateVal * Math.PI) / 180));
      const depth = 150 * sinRot * sinRot;

      panel.element.style.transform = `translateZ(-${depth}px) rotateX(${rotateVal}deg)`;
      if (-rotateVal === 0) {
        setHour(panel.element.textContent);
      }
    });
  };

  const updateTransform = (e: any) => {
    e.currentTarget.panels.forEach((panel: any) => {
      const rotateVal = -panel.progress * 20;
      const sinRot = Math.sin(Math.abs((rotateVal * Math.PI) / 180));
      const depth = 150 * sinRot * sinRot;

      panel.element.style.transform = `translateZ(-${depth}px) rotateX(${rotateVal}deg)`;
      if (-rotateVal === 0) {
        if (["am", "pm"].some((el) => el === panel.element.textContent)) {
          setAmpm(panel.element.textContent);
        } else {
          setMinutes(panel.element.textContent);
        }
      }
    });
  };

  const onClickDontRemember = () => {
    ReactGA.event("defaultBirthtimeSelected");
    ReactPixel.trackCustom("defaultBirthtimeSelected");
    dispatch(setBirthtime(`01:00 pm`));
  };

  return (
    <StepsTemplate
      lightTitle="What's your "
      yellowTitle="birth time?"
      text="Including your birth time enhances astrological precision for nuanced insights."
    >
      <>
        <div
          className="date-demo-wrapper has-text-white position-relative flex mt40"
          style={{ gap: "24px" }}
        >
          <Flicking
            className="date-flicking"
            horizontal={false}
            defaultIndex={6}
            onReady={updateTransformDay}
            onMove={updateTransformDay}
          >
            {dates(1, 12)}
            <ViewportSlot>
              <div
                className="shadow-top"
                style={{
                  height: "40px",
                }}
              ></div>
              <div
                className="shadow-bottom"
                style={{
                  height: "50px",
                }}
              ></div>
              <div
                className="date-panel-border-1"
                style={{
                  width: "100%",
                  left: "0px",
                }}
              />
            </ViewportSlot>
          </Flicking>
          <Flicking
            className="date-flicking"
            horizontal={false}
            defaultIndex={54}
            onReady={updateTransform}
            onMove={updateTransform}
          >
            {dates(1, 60)}
            <ViewportSlot>
              <div
                className="shadow-top"
                style={{
                  height: "40px",
                }}
              ></div>
              <div
                className="shadow-bottom"
                style={{
                  height: "50px",
                }}
              ></div>
              <div
                className="date-panel-border-2"
                style={{
                  width: "100%",
                  left: "0px",
                }}
              />
            </ViewportSlot>
          </Flicking>
          <Flicking
            className="date-flicking"
            horizontal={false}
            onReady={updateTransform}
            onMove={updateTransform}
          >
            <div className="date-panel">am</div>
            <div className="date-panel">pm</div>

            <ViewportSlot>
              <div
                className="date-panel-border-3"
                style={{
                  width: "100%",
                  left: "0px",
                }}
              />
            </ViewportSlot>
          </Flicking>
          {/* <div className="flex position-absolute date-panel-container full-width">
            <div
              className="date-panel-border-1"
              style={{
                left: "60px",
                width: "50px",
              }}
            />
            <div
              className="date-panel-border-2"
              style={{
                left: "160px",
                width: "50px",
              }}
            />
            <div
              className="date-panel-border-3"
              style={{
                left: "260px",
                width: "50px",
              }}
            />
          </div> */}
        </div>
        <p
          className="fRoboto gradient-pink-text font-s18 font-w400 mt16"
          style={{
            fontFamily: "Golos",
          }}
          onClick={onClickDontRemember}
        >
          I don’t remember
        </p>
        <div className="bottom-container-absolute">
          <DefaultButton
            text={"Next"}
            type="button"
            onClick={onSelectBirthday}
          />
        </div>
      </>
    </StepsTemplate>
  );
};

export default BirthTimeSelect;
