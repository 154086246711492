import React, { ChangeEvent, useEffect, useState } from "react";
import DefaultInput from "components/fields/DefaultInput";
import StepsTemplate from "../StepsTemplate";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useAppDispatch, useAppSelector } from "store";
import { goNextStep, setBirthplace } from "store/slices/registration";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import DefaultButton from "components/buttons/DefaultButton";

const PlaceOfBirthSelect = () => {
  const dispatch = useAppDispatch();
  const { birthplace, birthplaceLon, birthplaceLat } = useAppSelector(
    (state) => state.registration
  );

  const [place, setPlace] = useState(birthplace);
  const [error, setError] = useState(false);
  const [input, setInput] = useState(birthplace);
  const [coordinates, setCoordinates] = useState<{
    geometryLon: number;
    geometryLat: number;
  }>({ geometryLon: birthplaceLon, geometryLat: birthplaceLat });

  const [isShowPredictions, togglePredictions] = useState(false);
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE,
    });

  useEffect(() => {
    if (placePredictions.length) togglePredictions(true);
  }, [placePredictions]);

  useEffect(() => {
    if (!place.length) togglePredictions(false);
  }, [place]);

  console.log("aaa", birthplace, birthplaceLon, birthplaceLat);

  const onChangePlace = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    setCoordinates({ geometryLon: 0, geometryLat: 0 });
    setPlace(event.target.value);
    getPlacePredictions({ input: event.target.value });
  };

  const onSelectPlace = (val: { place_id: string }) => {
    ReactGA.event("birtPlaceSelected");
    ReactPixel.trackCustom("birtPlaceSelected");
    placesService?.getDetails(
      { placeId: val.place_id },
      (placeDetails: {
        formatted_address: string;
        geometry: { location: { lng: any; lat: any } };
      }) => {
        const serializablePlaceDetails = {
          formatted_address: placeDetails.formatted_address,
          geometryLon: placeDetails.geometry.location.lng(),
          geometryLat: placeDetails.geometry.location.lat(),
        };
        setPlace(serializablePlaceDetails.formatted_address);
        setCoordinates({
          geometryLon: serializablePlaceDetails.geometryLon,
          geometryLat: serializablePlaceDetails.geometryLat,
        });
      }
    );
    togglePredictions(false);
  };

  const goToNextStep = () => {
    if (coordinates.geometryLon && coordinates.geometryLat) {
      console.log("🚀 ~ goToNextStep ~ geometryLat:");
      setError(false);

      dispatch(
        setBirthplace({
          formatted_address: place,
          geometryLon: coordinates.geometryLon,
          geometryLat: coordinates.geometryLat,
        })
      );
      dispatch(goNextStep());
    } else {
      setError(true);
    }
  };

  return (
    <StepsTemplate
      lightTitle="Where were you "
      yellowTitle="born"
      secondLightTitle="?"
      text="Birthplace enriches your astrological profile for a deeper cosmic understanding."
    >
      <div style={{ marginTop: 138 }}>
        <DefaultInput
          handleChange={onChangePlace}
          name="city"
          placeholder="New York"
          inputVal={place}
          error={error && "Please provide your birthplace"}
          errorWithoutText={error}
        />
        {isShowPredictions && (
          <div
            className="default-border border-radius12"
            style={{
              backgroundColor: "#ecebf5ff",
            }}
          >
            {placePredictions.map((item, index) => (
              <div
                onClick={() => onSelectPlace(item)}
                key={index}
                className="padding-v8 t-align-l padding-h12 fRoboto brownish-gray-text cursor-pointer font-w400 bottom-border place-prediction font-s16 white-space-nowrap"
              >
                {item.description}
              </div>
            ))}
          </div>
        )}
        <div className="bottom-container-absolute">
          <DefaultButton
            text={"Next"}
            type="button"
            onClick={goToNextStep}
            isDisabled={!input.length }
          />
        </div>
      </div>
    </StepsTemplate>
  );
};

export default PlaceOfBirthSelect;
