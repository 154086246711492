import React, { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from "./HorizontalCard.module.scss";

type HorizontalCardProps = {
  type: string;
  isSelected: boolean;
  icon: () => ReactNode;
  setType: (val: string) => void;
};
const HorizontalCard: FC<HorizontalCardProps> = ({
  type,
  icon,
  isSelected,
  setType,
}) => (
  <button
    className={classNames(
      styles.card,
      { [styles.cardSelected]: isSelected },
      "pointer"
    )}
    onClick={() => setType(type)}
  >
    <span className={styles.icon}>{icon()}</span>
    <p className={styles.text}>{type}</p>
  </button>
);

export default HorizontalCard;