import React from "react";
import Sheet from "react-modal-sheet";
import styles from "./BottomModalSheet.module.scss";
import "./customStyles.scss";
import CloseBtnIcon from "../../assets/svg/close-btn";
import { Pie } from "react-chartjs-2";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import { useInView } from "react-intersection-observer";

const BottomModalSheet = ({
  isModalOpen,
  setOpen,
  data,
}: {
  isModalOpen: boolean;
  setOpen: (val: boolean) => void;
  data: any;
}) => {
  const [elemRef0, inView0] = useInView({
    threshold: 0,
  });
  const [elemRef1, inView1] = useInView({
    threshold: 0,
  });
  const [elemRef2, inView2] = useInView({
    threshold: 0,
  });
  console.log("");
  return (
    <>
      <Sheet isOpen={isModalOpen} onClose={() => setOpen(false)} disableDrag>
        <Sheet.Container className={styles.container}>
          <Sheet.Header>
            <div className={styles.headerContainer}>
              <h2>Your Horoscope</h2>
              <div onClick={() => setOpen(false)} className="pointer">
                <CloseBtnIcon />
              </div>
            </div>
          </Sheet.Header>
          <Sheet.Scroller>
            <div className={styles.container}>
              <Sheet.Scroller style={{paddingBottom: 30}}>
                <div className={styles.contentContainer}>
                  {data.map((item: any, index: number) => (
                    <div className={styles.item} key={index}>
                      <div className={styles.itemContainer}>
                        <h3 className="fGolos">{item.title}</h3>
                        <div className={styles.pieContainer}>
                          <div
                            className={styles.innerContainer}
                            ref={
                              index === 0
                                ? elemRef0
                                : index === 1
                                  ? elemRef1
                                  : elemRef2
                            }
                          >
                            {(index === 0
                              ? inView0
                              : index === 1
                                ? inView1
                                : inView2) && (
                              <Pie
                                data={{
                                  datasets: [
                                    {
                                      data: [100 - item.percent, item.percent],
                                      backgroundColor: ["#EDECF7", item.color],
                                      borderWidth: 0,
                                    },
                                  ],
                                }}
                              />
                            )}
                          </div>
                          <p className={styles.text}>{item.percent}%</p>
                        </div>
                      </div>
                      <p>{item.text}</p>
                    </div>
                  ))}
                </div>
              </Sheet.Scroller>
            </div>
          </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop />
        <RemoveScrollBar />
      </Sheet>
    </>
  );
};

export default BottomModalSheet;
