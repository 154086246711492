import React, { useState } from "react";
import CustomHeightBottomSheet from "components/CustomHeightBottomSheet/CustomHeightBottomSheet";
import classNames from "classnames";
import DefaultButton from "components/buttons/DefaultButton";
import Guarantee from "assets/svg/guarantee.svg";
import http from "helpers/http";
import { useAppDispatch, useAppSelector } from "store";
import { isValidEmail } from "utils/validations";
import { useNavigate } from "react-router-dom";
import { setUserData } from "store/slices/user";
import { setToken } from "helpers/localStorageHelpers";
import { getYesterday } from "utils/dates";
import { ClassNames, Email } from "@smastrom/react-email-autocomplete";
import { startLoading, stopLoading } from "../../../../store/slices/loader";

const EmailModal = ({
  isModalOpen,
  toggleButtonModal,
  toggleModal,
}: {
  isModalOpen: boolean;
  toggleModal: (val: boolean) => void;
  toggleButtonModal: (val: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | any>("");
  const [isLoading, setIsLoading] = useState(false);
  const baseList = ["gmail.com", "yahoo.com", "hotmail.com", "icloud.com"];

  const {
    gender,
    relationshipStatus,
    preferColor,
    naturalElement,
    birthday,
    zodiacName,
    birthplace,
    birthtime,
    birthplaceLon,
    birthplaceLat,
    palmPhotoUrl,
  } = useAppSelector((state) => state.registration);

  const onEnterEmail = (val: string) => {
    if (error) {
      setError("");
    }
    setEmail(val);
  };

  const onRegistration = async () => {
    setIsLoading(true);
    const res: any = await http.post("/auth/register", {
      genderType: gender,
      placeOfBirth: birthplace,
      relationshipStatus,
      birthTime: birthtime,
      birthDayDate: new Date(birthday),
      zodiacName,
      naturalElement,
      preferColor,
      email,
      birthplaceLon,
      birthplaceLat,
      expirationDate: getYesterday(),
      base64Image: palmPhotoUrl,
    });

    dispatch(
      setUserData({
        userData: {
          genderType: gender,
          placeOfBirth: birthplace,
          relationshipStatus,
          birthTime: "",
          birthDayDate: birthday,
          zodiacName: zodiacName,
          naturalElement: naturalElement,
          preferColor: preferColor,
          email,
          birthplaceLon: "",
          birthplaceLat: "",
          expirationDate: getYesterday(),
          palmPhotoUrl: res.palmPhotoUrl,
        },
      }),
    );
    setToken(res.token);
    toggleModal(false);
    toggleButtonModal(true);
    setIsLoading(false);
    navigate("/analysing");
  };

  const onConfirmEmail = async () => {
    if (isValidEmail(email)) {
      try {
        dispatch(startLoading());
        await onRegistration();
        const container = document.getElementById("main-container");
        if (container) container.style.overflow = "auto";
        setIsLoading(false);
      } catch (err: any) {
        setIsLoading(false);
        dispatch(stopLoading());
        if (err.code == 409) {
          setError(
            <>
              You already have an account.
              <button
                className="linkBtn font-s10 font-w600 ml2 text-uppercase"
                onClick={() => {
                  toggleModal(false);
                  navigate("/login");
                }}
              >
                Sign in
              </button>
            </>,
          );
        } else {
          console.log(err.message);
        }
      }
    } else {
      setIsLoading(false);
      setError("Please provide your email");
    }
  };

  const myClassNames: ClassNames = {
    wrapper: "my-wrapper",
    input: error ? "input input-error" : "input",
    suggestion: "suggestion-item",
  };

  console.log("error", error);

  const modalContent = () => {
    return (
      <div className={classNames("mt24")}>
        <Email
          placeholder={"youremail@gmail.com"}
          classNames={myClassNames}
          baseList={baseList}
          onChange={onEnterEmail}
          value={email}
        />
        {error && <p className="errorMessage">{error}</p>}
        <DefaultButton
          customStyles={"mt32"}
          text="Next"
          type="button"
          onClick={onConfirmEmail}
          isDisabled={isLoading}
        />
        <div className="flex mt12 gap4 i-align-c brownish-gray-text fMontserrat font-s8 text-uppercase">
          <img src={Guarantee} alt={""} />
          <p>
            Your personal data is safe wi th us. We’ll use your email for
            updates, receipts and subscription details
          </p>
        </div>
      </div>
    );
  };

  const headerContent = () => {
    return (
      <h2>
        <span className={"gradient-pink-text"}>Sign up</span> for self- <br />discovery with
        Astro.Day
      </h2>
    );
  };

  return (
    <CustomHeightBottomSheet
      modalHeight={316}
      content={modalContent}
      header={headerContent}
      isModalOpen={isModalOpen}
      setOpen={toggleModal}
    />
  );
};

export default EmailModal;
