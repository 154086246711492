import React, { FC, useEffect } from "react";
import Layout from "components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import GenderSelect from "./steps/GenderSelect/GenderSelect";
import BirthDaySelect from "./steps/BirthDaySelect/BirthDaySelect";
import BirthTimeSelect from "./steps/BirthTimeSelect/BirthTimeSelect";
import Header from "./components/header/Header";
import PlaceOfBirthSelect from "./steps/PlaceOfBirthSelect/PlaceOfBirthSelect";
import InfoStep from "./steps/InfoStep/InfoStep";
import RelationshipSelect from "./steps/RelationshipSelect/RelationshipSelect";
import CustomProgressBar from "components/progressBar/ProgressBar";
import http from "helpers/http";
import NaturalElementSelect from "./steps/NaturalElementSelect/NaturalElementSelect";
import ColorSelect from "./steps/ColorSelect/ColorSelect";
import InfoAboutPalmsStep from "./steps/InfoAboutPalmsStep/InfoAboutPalmsStep";
import InstructionStep from "./steps/InstructionStep/InstructionStep";
import styles from "./Registration.module.scss";
import { useAppDispatch, useAppSelector } from "store";
import PalmPhotoStep from "./steps/PalmPhotoStep/PalmPhotoStep";
import {
  clearRegistrationState,
  setBirthplace,
} from "store/slices/registration";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import Loader from "components/Loader/Loader";
import { getUserLocationString } from "../../utils/geolocation";
import classNames from "classnames";
import PalmLineDetection from "./steps/PalmLineDetection/PalmLineDetection";
import { setCampainId } from "../../helpers/localStorageHelpers";

import LogRocket from "logrocket";
LogRocket.init("nwxyyv/astroday");

const searchParams = new URLSearchParams(window.location.search);
const campainId = searchParams.get("campainId");

const Registration: FC = () => {
  const { step } = useAppSelector((state) => state.registration);
  const { isPalmPhotoFromUpload, palmPhotoUrl } = useAppSelector(
    (state) => state.registration
  );
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(clearRegistrationState());
  };
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/registration",
      title: "Registration Page",
    });
    ReactPixel.track("Registration Page");
    // handleClose();
    // clearPersistor();
    if (campainId) {
      http.post("/analytic/save-campain-id", { campainId });
      setCampainId(campainId);
      navigate("/registration", { replace: true });
    }
    const getUserLocation = async () => {
      const res: any = await getUserLocationString();
      if (res.status === "success") {
        if (res.formatted_address) {
          dispatch(
            setBirthplace({
              formatted_address: res.formatted_address,
              geometryLon: res.geometryLon,
              geometryLat: res.geometryLat,
            })
          );
        }
      }
    };
    getUserLocation();
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", handleClose);
    return () => window.removeEventListener("beforeunload", handleClose);
  }, [dispatch]);

  return (
    <Layout>
      <div
        className={classNames("container", {
          ["palm-container"]: step === 11,
          ["padding-b40"]: step !== 11,
        })}
        style={{
          backgroundImage: "url(./assets/background.svg)",
          backgroundPosition: 'center center',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
        }}
      >
        <div
          className={"position-relative height100"}
        >
          {step !== 11 && (
            <>
              {step !== 1 && <Header />}
              {step !== 1 && (
                <CustomProgressBar
                  completed={step}
                  maxCompleted={11}
                  customBarWrapperStyles={styles.progressBarWrapper}
                  customBarContainerStyles={
                    "progress-bar-container-electric-blue"
                  }
                />
              )}
            </>
          )}
          {step === 1 && <GenderSelect />}
          {step === 2 && <BirthDaySelect />}
          {step === 3 && <BirthTimeSelect />}
          {step === 4 && <PlaceOfBirthSelect />}
          {step === 5 && <InfoStep />}
          {step === 6 && <RelationshipSelect />}
          {step === 7 && <InfoAboutPalmsStep />}
          {step === 8 && <NaturalElementSelect />}
          {step === 9 && <ColorSelect />}
          {step === 10 && <InstructionStep />}
          {step === 11 && (isPalmPhotoFromUpload || palmPhotoUrl) && (
            <PalmLineDetection />
          )}
          {step === 11 && !isPalmPhotoFromUpload && !palmPhotoUrl && (
            <PalmPhotoStep />
          )}
        </div>
        <Loader />
      </div>
    </Layout>
  );
};

export default Registration;